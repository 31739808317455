<template>
  <div class="payment">
    <div class="wrapper-head">
      <div class="head-left">
        <div class="left-tips">{{$t('payment.ddtjcg')}}</div>
        <div class="left-tips-time">{{$t('payment.qnjkwczf')}}</div>
        <div>
          <count-down v-if="endTime"
                      :endTime="endTime"
                      @timeoutState="timeoutState"
                      :callback="callback"
                      :endText="$t('payment.dycsqx')"></count-down>
        </div>
      </div>
      <div class="head-right">
        {{$t('payment.yfje')}}<span> {{ moneyfilter(orderPay.total_price) }}</span>{{$t('payment.yuan')}}
      </div>
    </div>
    <div v-show="!qrpay"
         class="wrapper-box">
      <div class="box-item"
           @click="addPay(item)"
           v-for="(item, index) in payment"
           :key="index">
        <img :src="item.img"
             alt="" />
        <span>{{ item.name }}</span>
      </div>
    </div>

    <div v-show="qrpay"
         class="pay-way">
      <div class="contents">
        <div class="qrpay-box">
          <div class="qrpay">
            {{ payWay.pay_type == "WXPAY_NATIVE" ? $t('payment.wx') : $t('payment.zfb') }}
          </div>
        </div>
      </div>
      <div class="content-box"
           v-if="payType">
        <div class="qrcode">
          <div id="qrcode"></div>
          <div class="screen">
            <i class="el-icon-full-screen"></i> {{$t('payment.qsywxsmzf')}}
          </div>
        </div>
        <div class="but">
          <p>{{$t('payment.zfcghzdtz')}}</p>
          <el-button size="small"
                     plain
                     @click="queryPay">{{$t('payment.cxzf')}}</el-button>
          <el-button size="small"
                     type="success"
                     @click="payState">{{$t('payment.zfcg')}}</el-button>
        </div>
      </div>

      <!-- <div v-else>333333333</div> -->
    </div>
    <div v-if="smallScreen"
         :style="{ height: bottomdistance + 'px' }"></div>
  </div>
</template>

<script>
import CountDown from "../../components/common/countDown.vue";
import QRCode from "qrcodejs2";
export default {
  components: {
    CountDown,
  },
  name: "payment",

  data () {
    return {
      orderPay: {},
      endTime: "",
      payment: [
        {
          name: "支付宝",
          type: "ALIPAY_PC",
          img: require("../../assets/pay/a9936a369e82e0c6c42112674a5220e8_fullsize.jpg"),
        },
        {
          name: "微信",
          type: "WXPAY_NATIVE",
          img: require("../../assets/pay/u=3774939867,2826752539&fm=74&app=80&f=JPEG&size=f121,121.jpg"),
        },
      ],
      qrpay: false,
      payWay: {},
      payType: true,
      timeState: false,
      smallScreen: false,
      bottomdistance: 0,
    };
  },

  mounted () {
    // 是否是微信浏览器
    if (navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == "micromessenger") {
      this.qrpay = false
    } else {
      this.qrpay = true;
    }

    this.getOrderPay();
    this.wxConfig();
    var clientWidth = document.documentElement.clientWidth;
    if (clientWidth < 750) {
      this.smallScreen = true;
      this.bottomdistance = clientWidth / 6.405;
      console.log(this.bottomdistance);
    }
  },

  methods: {
    async wxConfig () {
      if (
        navigator.userAgent.indexOf("MicroMessenger") ||
        navigator.userAgent.indexOf("Weixin") != -1 ||
        navigator.userAgent.indexOf("wechat") != -1
      ) {
        let res = await this.api.post(this.apiPath.queryPayState2, {
          order_no: document.location.href,
        });
        console.log(res);
        // 注入权限配置
        this.$wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.appid, // 必填，公众号的唯一标识
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.nonceStr, // 必填，生成签名的随机串
          signature: res.signature, // 必填，签名，见附录1
          jsApiList: ["chooseWXPay"], // 必填，
        });
      }
    },
    async getOrderPay () {
      let order_no = JSON.parse(localStorage.getItem("order_no"));
      let orderPay = await this.api.get(
        this.apiPath.orderPay + `${order_no}`,
        {}
      );
      this.ss = orderPay.data;
      this.orderPay = orderPay.data;
      var newstring = orderPay.data.ending_rub.toString().substring(0, 10);
      this.endTime = newstring;
      console.log(orderPay);
    },
    timeoutState (e) {
      //订单是否超时
      this.timeState = e;
    },
    callback () {
      console.log(111);
    },
    async addPay (item) {
      console.log(item);
      // alert(navigator.userAgent)
      if (
        (navigator.userAgent.indexOf("MicroMessenger") != -1 ||
          navigator.userAgent.indexOf("Weixin") != -1 ||
          navigator.userAgent.indexOf("wechat") != -1) &&
        item.type == "WXPAY_NATIVE"
      ) {
        let data = {
          amount: this.ss.total_price,
          pay_type: item.type,
          order_no: this.ss.order_no,
          phone: this.ss.user_phone,
        };
        data["openid"] = localStorage.getItem("openid");
        console.log(data);
        let res = await this.api.post(this.apiPath.prepayOrder, data);
        console.log(res);
        //   // console.log('调用app支付')
        //   // console.log(this.$wx)
        this.$wx.ready(() => {
          this.$wx.chooseWXPay({
            appId: res.appId,
            timestamp: res.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: res.nonceStr, // 支付签名随机串，不长于 32 位
            package: res.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: "MD5", // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            paySign: res.paySign, // 支付签名
            success: (res) => {
              console.log(res);
              this.$message({
                message: "支付成功",
                type: "success",
              });
              this.payState();
              this.$router.push("MyOrder");
              // 支付成功回调
            },
            fail: (err) => {
              // 失败回调
              console.log(err);
              this.$message.error("未支付");
            },
          });
        });

        //   // this.$wx.requestPayment()
      } else {
        if (this.timeState) {
          this.$message({
            message: "订单已超时，请重新购买！",
            type: "warning",
          });
          return;
        }
        this.qrpay = true;
        // if(this.orderPay.total_price) {
        let data = {
          amount: this.ss.total_price,
          pay_type: item.type,
          order_no: this.ss.order_no,
          phone: this.ss.user_phone,
        };
        let res = await this.api.post(this.apiPath.payOrder, data);
        console.log(res);
        if (res.code == 0) {
          this.$message({
            message: res.msg,
            type: "success",
          });
        } else {
          this.$message({
            message: res.message,
            type: "warning",
          });
          return;
        }
        console.log(res)
        this.orderPay = res;
        this.payWay = data;
        if (item.type == "WXPAY_NATIVE") {
          this.payType = true;
          this.getCoed(res.data.codeUrl);
        } else {
          this.payType = false;
          console.log("支付宝");
          const newTab = window.open();
          const div = document.createElement("div");
          div.innerHTML = res.data.body;
          newTab.document.body.appendChild(div);
          newTab.document.forms[0].acceptCharset = "utf-8";
          newTab.document.forms[0].submit();

          this.$confirm("是否成功支付?", "提示", {
            confirmButtonText: "已经支付",
            cancelButtonText: "取消支付",
            type: "warning",
          })
            .then(() => {
              this.addPayState(data.order_no);
            })
            .catch(() => {
              this.qrpay = false;
              this.getOrderPay();
              this.$message({
                type: "info",
                message: "已取消支付",
              });
            });
        }
      }

      // }
    },
    queryPay () {
      if (document.querySelectorAll(".qrcode img")) {
        document.querySelectorAll(".qrcode img").forEach((img) => img.remove());
      }
      this.qrpay = false;
    },
    payState () {
      this.addPayState(JSON.parse(localStorage.getItem("order_no")));
    },
    async addPayState (order_no) {
      let data = {
        order_no: order_no,
      };
      let res = await this.api.post(this.apiPath.queryPayState, data);
      if (res.data == "SUCCESS") {
        this.$message({
          message: "支付成功",
          type: "success",
        });
        this.$router.push("MyOrder");
      } else {
        this.qrpay = false;
        this.$message.error("未支付");
      }
    },
    getCoed (e) {
      console.log(e);
      let qrcode = new QRCode("qrcode", {
        width: 200, // 宽
        height: 200, // 高
        text: e, // 二维码内容,也可以直接放接口获取的数据
        // render: 'canvas'     // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        // background: '#f0f'   // 自定义颜色默认黑白
        // foreground: '#ff0'
      });
    },
  },
};
</script>

<style lang="less" scoped>
.payment {
  .wrapper-head {
    font-weight: bold;
    padding: 20px 40px;
    width: 100%;
    margin: 20px auto;
    display: flex;
    justify-content: space-around;
    .head-right {
      font-size: 18px;
      span {
        color: red;
      }
    }
    .left-tips {
      font-size: 21px;
      margin-bottom: 10px;
    }
    .left-tips-time {
      margin-bottom: 20px;
    }
  }
  .wrapper-box {
    padding: 0 10%;
    transition: 0.35s;
    height: auto;
    .box-item {
      padding: 20px;
      margin-bottom: 10px;
      background: #eee;
      cursor: pointer;
      img {
        width: 80px;
        height: 80%;
        border-radius: 10px;
      }
      span {
        font-size: 18px;
        margin-left: 20px;
      }
    }
    .box-item:hover {
      color: red;
    }
  }
  .pay-way {
    background: #eee;
    margin: 0 10%;
    padding: 40px;
    .contents {
      display: flex;
      justify-content: space-between;
      .qrpay-box {
        font-size: 15px;
        font-weight: bold;
      }
      .but {
        color: red;
        cursor: pointer;
      }
    }
    .content-box {
      width: 100%;
      display: flex;
      margin-top: 60px;
      .qrcode {
        margin: 0 10%;
        .screen {
          background: #ff7674;
          padding: 5px;
          color: #fff;
          text-align: center;
        }
        img {
          width: 200px;
          height: 200px;
        }
      }
      .but {
        margin-top: 70px;
        p {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
<style>
@media screen and (max-width: 750px) {
  .payment .wrapper-box .box-item img {
    display: block;
    height: unset !important;
  }
  .payment .wrapper-box {
    display: flex;
    justify-content: space-around;
  }
  .payment .wrapper-box .box-item {
    text-align: center;
    background-color: #fff !important;
  }
  .payment .wrapper-box .box-item span {
    margin-left: 0 !important;
  }
  .payment .pay-way .content-box {
    flex-direction: column;
  }
  .payment .pay-way .content-box .but {
    margin-top: 10px !important;
    text-align: center;
  }
  .payment .pay-way .content-box .but p {
    text-align: left;
  }
}
</style>
